import { fileUpload, imageSize } from '../../../mixin/convertImgUrlToBase64';

const marginDefaults = [0.5, 0.25, 0.5, 0.5];
const backgroundColor = 'ffffff';

const slideMasterDefaults = [
  {
    image: {
      path: "/images/FirstStep-RGB-Line.png",
      x: 0.5,
      y: 0.1,
      w: 3  ,
      h: 1,
    },
  },
  // {
  //   image: {
  //     path: "/images/FirstStep-logo-report.png",
  //     x: 7.3,
  //     y: 10.3,
  //     h: 0.58,
  //     w: 1,
  //   },
  // },
];

const slideTitleDefaults = [
  {
    image: {
      path: '/images/First-Step-Report-Cover.jpg',
      x: 0,
      y: 0,
      w: 8.5,
      h: 11,
    },
  },
];

// the only reason this master slide exists is to remove the contents from the footer on the title page
export const removeFooterContents = async (self, user, evaluationData) => {
  let slideMasterContent = [...slideMasterDefaults];
  let evaluation = null;
  if (evaluationData) {
    evaluation = evaluationData;
  }

  // function getDate(timestamp) {
  //   let date = new Date(timestamp);
  //   var monthNames = [
  //     app.$t('Jan.'),
  //     app.$t('Feb.'),
  //     app.$t('Mar.'),
  //     app.$t('Apr.'),
  //     app.$t('May'),
  //     app.$t('Jun.'),
  //     app.$t('Jul.'),
  //     app.$t('Aug.'),
  //     app.$t('Sep.'),
  //     app.$t('Oct.'),
  //     app.$t('Nov.'),
  //     app.$t('Dec.'),
  //   ];
  //   var day = date.getDate();
  //   var monthIndex = date.getMonth();
  //   var year = date.getFullYear();
  //   return day + ' ' + monthNames[monthIndex] + ' ' + year;
  // }

  const language = self.app.$store.state.i18n.locale;

  // calculate the size of branding image based on the aspect ratio of the branding image
  // umair works starts here.
  // let maxHeight = 0.75;
  // let maxWidth = 1.15;
  // // console.log({ slideMasterContent: evaluation });
  // let { brandingImageHeight, brandingImageWidth, brandingImageBase64 } =
  //   evaluation.clientData || {};
  // if (!brandingImageBase64) {
  //   ({ brandingImageHeight, brandingImageWidth, brandingImageBase64 } = user);
  // }

  // const { w, h } = calculateAspectRatioFit(
  //   brandingImageHeight,
  //   brandingImageWidth,
  //   maxWidth,
  //   maxHeight
  // );

  // let imageInfo;
  // if (brandingImageBase64) {
  //   imageInfo = {
  //     image: {
  //       data: brandingImageBase64,
  //       x: 7,
  //       y: 0.15,
  //       w,
  //       h,
  //     },
  //   };
  // }

  // imageInfo && slideMasterContent.push(imageInfo);
  // umair works end here

  let maxHeight = 0.75;
  let maxWidth = 1.15;
  let { brandingImageHeight, brandingImageWidth, brandingImageBase64 } =
    evaluation.clientData || {};
  if (!brandingImageBase64) {
    ({ brandingImageHeight, brandingImageWidth, brandingImageBase64 } = user);
  }
  let imageInfo = {
    image: {
      data: brandingImageBase64,
      x: 7,
      y: 0.25,
    },
  };

  if (brandingImageHeight !== undefined && brandingImageWidth !== undefined) {
    imageInfo.image.w = brandingImageWidth;
    imageInfo.image.h = brandingImageHeight;
    if (brandingImageWidth / brandingImageHeight < maxWidth / maxHeight) {
      imageInfo.image.sizing = {
        type: 'contain',
        w: (maxHeight * brandingImageWidth) / brandingImageHeight,
        h: maxHeight,
      };
    } else {
      imageInfo.image.sizing = {
        type: 'contain',
        w: maxWidth,
        h: (maxWidth * brandingImageHeight) / brandingImageWidth,
      };
    }
  }

  imageInfo && slideMasterContent.push(imageInfo);

  self.pptx.defineSlideMaster({
    title: 'Title Slide',
    margin: marginDefaults,
    bkgd: backgroundColor,
    objects: slideTitleDefaults,
  });

  self.pptx.defineSlideMaster({
    title: 'Slide master',
    margin: marginDefaults,
    bkgd: backgroundColor,
    objects: [
      ...slideMasterContent,
      {
        text: {
          text: self.app.$store.getters.getDate(new Date()) + app.$t(' | '),
          options: {
            x: 0.18,
            y: 10.45,
            color: '000000',
            fontFace: 'Arial',
            fontSize: 10,
          },
        },
      },
      {
        text: {
          text:
           '© 2023 Zinpro Corporation. ' + app.$t('All Rights Reserved.') + ' | ' +
            app.$t(
              'Phone: 800-445-6145 or 952-983-4000  |  Email: contact@zinpro.com'
            ),
          options: {
            x: 0.18, // language === null ? 4 : 3.5,
            y: 10.65,
            color: '000000',
            fontFace: 'Arial',
            fontSize: 6,
          },
        },
      },
      {
        line: {
          x: 0.5,
          y: 1.95,
          w: 7.5,
          h: 0,
          line: 'dddddd',
          lineSize: 1,
        },
      },
      {
        line: {
          x: 0.18,
          y: 10.2,
          w: 8.1,
          h: 0,
          line: 'dddddd',
          lineSize: 1,
        },
      },
      {
        text: {
          text: app.$t(
            'Zinpro does not guarantee the accuracy of this report nor its usefulness in achieving any purpose.  Readers must not rely on it as an alternative to advice from a qualified professional.'
          ),
          options: {
            x: 0.18, 
            y: language === null ? 10.75 : 10.8,
            w: 7,
            color: '000000',
            fontSize: 6,
            fontFace: 'Arial',
          },
        },
      },
    ],
    slideNumber: {
      x: 1.2,
      y: 10.32,
      fontFace: 'Arial',
      fontSize: 10,
      color: '000000',
    },
  });
};

/**
 * Conserve aspect ratio of the original region. Useful when shrinking/enlarging
 * images to fit into a certain area.
 *
 * @param {Number} srcWidth width of source image
 * @param {Number} srcHeight height of source image
 * @param {Number} maxWidth maximum available width
 * @param {Number} maxHeight maximum available height
 * @return {Object} { width, height }
 */
export function calculateAspectRatioFit(
  srcWidth,
  srcHeight,
  maxWidth,
  maxHeight
) {
  const ratio = Math.min(maxWidth / srcWidth, maxHeight / srcHeight);
  return { width: srcWidth * ratio, height: srcHeight * ratio };
}
