<template>
  <section>
    <!-- START MOBILE SORT -->
    <a
      v-if="evaluations && evaluations.length"
      href="#"
      class="btn btn-primary btn-primary--thin btn-primary--thin--short btn-full--sm d-lg-none"
      data-toggle="modal"
      data-target="#sort"
      >Sort</a
    >
    <div class="modal fade" id="sort">
      <div class="modal-dialog modal-dialog--edit">
        <div class="modal-content edit-modal">
          <div class="modal-body">
            <h2 class="h2 h2--sort">{{ 'Sort By' | translate }}</h2>
            <hr class="my-4" />
            <select
              name="company"
              class="form-control text-center"
              aria-required="true"
              aria-invalid="true"
            >
              <option value="">{{ 'Ascending' | translate }}</option>
              <option value="manual">{{ 'Chronological' | translate }}</option>
            </select>
            <div class="modal__radio-item">
              <p class="modal__radio-item__p">{{ 'Evaluation' | translate }}</p>
              <input
                type="radio"
                class="modal__radio-item__radio"
                v-model="sorted"
                value="name"
              />
            </div>
            <div class="modal__radio-item">
              <p class="modal__radio-item__p">{{ 'Groups' | translate }}</p>
              <input
                type="radio"
                class="modal__radio-item__radio"
                v-model="sorted"
                value="total_group_count"
              />
            </div>
            <div class="modal__radio-item">
              <p class="modal__radio-item__p">{{ 'Dairy' | translate }}</p>
              <input
                type="radio"
                class="modal__radio-item__radio"
                v-model="sorted"
                value="dairy"
              />
            </div>
            <div class="modal__radio-item">
              <p class="modal__radio-item__p">{{ 'Company' | translate }}</p>
              <input
                type="radio"
                class="modal__radio-item__radio"
                v-model="sorted"
                value="company"
              />
            </div>
            <div class="modal__radio-item">
              <p class="modal__radio-item__p">{{ 'Date' | translate }}</p>
              <input
                type="radio"
                class="modal__radio-item__radio"
                v-model="sorted"
                value="date"
              />
            </div>
            <hr class="my-4" />
            <div class="row modal-bottom">
              <div class="col-md-12">
                <button
                  type="button"
                  class="mb-3 btn btn-primary btn-primary--modal"
                  data-dismiss="modal"
                  @click="sort(sorted.toString())"
                >
                  {{ 'Done' | translate }}
                </button>
                <a
                  href="#"
                  class="btn-cancel-modal d-flex justify-content-center"
                  data-dismiss="modal"
                  >{{ 'Cancel' | translate }}</a
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- END MOBILE SORT -->

    <!-- START ERROR MESSAGE -->
    <div v-if="errorMsg" class="alert alert-danger mt-4 mb-0" role="alert">
      {{ errorMsg | translate }}
    </div>
    <!-- END ERROR MESSAGE -->

    <div
      v-if="evaluations"
      class="card main-card main-card--evaluations pt-lg-3"
    >
      <!-- START COLUMN HEADINGS -->
      <div id="columns" class="row d-none d-sm-none d-md-none d-lg-flex mx-0">
        <div class="offset-1 col-17 col-lg-6 heading">
          <span
            class="pointer"
            @click="
              sort('name');
              sortedEvaluations();
            "
          >
            {{ 'Evaluations' | translate }}
            <img
              class="ml-1 sort"
              src="@/assets/icons/Sortiicon.svg"
              alt="sort by name"
            />
          </span>
        </div>
        <div class="col-lg-3 heading ">
          <span
            class="pointer"
            @click="
              sort('total_group_count');
              sortedEvaluations();
            "
          >
            {{ 'Groups' | translate }}
            <img
              class="ml-1 sort"
              src="@/assets/icons/Sortiicon.svg"
              alt="sort by group"
            />
          </span>
        </div>
        <div class="col-lg-5 heading">
          <span
            class="pointer"
            @click="
              sort('dairy');
              sortedEvaluations();
            "
          >
            {{ 'Dairy' | translate }}
            <img
              class="ml-1 sort"
              src="@/assets/icons/Sortiicon.svg"
              alt="sort by dairy"
            />
          </span>
        </div>
        <div class="col-lg-4 heading">
          <span
            class="pointer"
            @click="
              sort('company');
              sortedEvaluations();
            "
          >
            {{ 'Company' | translate }}
            <img
              class="ml-1 sort"
              src="@/assets/icons/Sortiicon.svg"
              alt="sort by company"
            />
          </span>
        </div>
        <div class="col-lg-3 heading">
          <span
            class="pointer"
            @click="
              sort('date');
              sortedEvaluations();
            "
          >
            {{ 'Date' | translate }}
            <img
              class="ml-1 sort"
              src="@/assets/icons/Sortiicon.svg"
              alt="sort by date"
            />
          </span>
        </div>
      </div>
      <!-- END COLUMN HEADINGS -->

      <div v-if="loading">
        <div class="loading loading--sm">
          <div class="double-bounce1"></div>
          <div class="double-bounce2"></div>
        </div>
      </div>
      <div
        v-else-if="evaluations === undefined || evaluations.length === 0"
        class="mt-4 alert alert-danger"
      >
        <p class="mb-0">{{ 'No evaluations have been added!' | translate }}</p>
      </div>
      <!-- START EVALUATION LIST -->
      <div
        v-else
        class="item mt-3"
        v-for="(evaluation, index) in evaluations"
        :key="index"
      >
        <div class="accordion-heading--wrapper">
          <div
            :id="[userType + '-evaluation-' + index]"
            class="accordion-heading row mx-0"
          >
            <div
              class="open-eval col-3 col-sm-2 col-lg-1"
              data-toggle="collapse"
              :href="['#' + userType + 'EvaluationAccordion' + index]"
              aria-expanded="false"
              :aria-controls="[userType + 'EvaluationAccordion' + index]"
            >
              <i
                class="fa fa-angle-up fa-angle-up--eval"
                aria-hidden="true"
              ></i>
              <i
                class="fa fa-angle-down fa-angle-down--eval"
                aria-hidden="true"
              ></i>
            </div>
            <div class="col-18 col-xs-19 col-sm-18 col-lg-6 heading">
              {{ evaluation.name }}
            </div>
            <div class="col-md-3 d-none d-sm-none d-md-none d-lg-block heading">
              {{
                evaluation.groups ? Object.keys(evaluation.groups).length : '0'
              }}
            </div>
            <div class="col-md-5 d-md-none d-none d-sm-none d-lg-block heading">
              {{ getDairyLocationName(evaluation) }}
            </div>
            <div class="col-md-4 d-sm-none d-none d-md-none d-lg-block heading">
              {{ getClientName(evaluation) }}
            </div>
            <div class="col-md-3 d-sm-none d-none d-md-none d-lg-block heading">
              {{ $store.getters.getDate(evaluation.date_created) }}
            </div>
            <div class="col-lg-2 col-md-4 col-3 heading">
              <modal-ellipsis :value="userType + index" class="pull-right">
                <template slot="modal-title">
                  {{ 'What Would You Like To Do?' | translate }}
                </template>
                <template slot="modal-content">
                  <router-link
                    class="btn btn-full d-block btn-full--modal mb-2"
                    :to="'/users/' + userId + '/evaluations/' + evaluation._id"
                    data-dismiss="modal"
                  >
                    {{ 'Edit' | translate }}
                  </router-link>
                  <button
                    data-dismiss="modal"
                    data-toggle="modal"
                    :data-target="['#areYouSure-' + userType + index]"
                    class="btn btn-full btn-full--modal"
                  >
                    {{ 'Delete' | translate }}
                  </button>
                  <div class="row">
                    <div class="col-md-24 modal-bottom mt-2">
                      <button class="btn-cancel-modal" data-dismiss="modal">
                        {{ 'Cancel' | translate }}
                      </button>
                    </div>
                  </div>
                </template>
              </modal-ellipsis>

              <div
                class="modal fade"
                :id="['areYouSure-' + userType + index]"
                tabindex="-1"
                role="dialog"
                :aria-labelledby="['areYouSure-' + userType + index]"
                aria-hidden="true"
              >
                <div class="modal-dialog" role="document">
                  <div class="modal-content edit-modal">
                    <h2 class="h2 h2--modal text-center mt-3 mt-md-0">
                      {{ 'Are you sure?' | translate }}
                    </h2>
                    <div class="modal-body my-0">
                      <a
                        @click="deleteEvaluation(evaluation._id)"
                        href="#"
                        data-dismiss="modal"
                        class="btn btn-primary btn-full d-block mb-2"
                        >{{ 'Yes' | translate }}</a
                      >
                      <a
                        href="#"
                        data-dismiss="modal"
                        class="btn btn-secondary btn-full btn-full--modal mb-2"
                        >{{ 'No' | translate }}</a
                      >
                      <div class="row mt-2">
                        <div class="col-md-24 modal-bottom">
                          <a
                            href="#"
                            class="btn-cancel-modal"
                            data-dismiss="modal"
                            >{{ 'Cancel' | translate }}</a
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- end modal -->
            </div>
          </div>
        </div>

        <!-- end accordion-heading--wrapper -->
        <div
          :id="[userType + 'EvaluationAccordion' + index]"
          class="collapse pt-1 pb-3 pb-sm-0"
          role="tabpanel"
        >
          <div class="row evaluations-row d-md-none mx-0">
            <div class="col-md-23 offset-1">
              <h3 class="h3">
                {{ getDairyLocationName(evaluation) }}
              </h3>
              <h3 class="h3">{{ getClientName(evaluation) }}</h3>
              <p class="p--large mb-2">
                {{ $store.getters.getDate(evaluation.date_created) }}
              </p>
            </div>
          </div>
          <div class="row mx-0 evaluations-row background-dark-grey">
            <div class="col-22 py-0 offset-1">
              <h3 class="h3 mb-0">{{ 'Assessors & Groups' | translate }}</h3>
            </div>
          </div>
          <div
            class="row mx-0 evaluations-row"
            v-for="(assessorType, index) in getEvaluationOrder(evaluation)"
            :key="index"
          >
            <template v-if="checkIsAssessorsExist(evaluation)">
              <div class="col-md-5 col-xs-16 col-19 offset-1">
                <router-link
                  class="evaluations-row__link"
                  :to="
                    '/users/' +
                      userId +
                      '/evaluations/' +
                      evaluation._id +
                      '/groups/' +
                      getFirstGroupByAssessorType(
                        evaluation.groups,
                        assessorType
                      ).id +
                      '/assessors/' +
                      assessorType +
                      '/'
                  "
                  >{{
                    getAssessorTypeNameById(assessorType) | translate
                  }}</router-link
                >
              </div>
              <div class="offset-md-16 col-3 col-md-2 text-right">
                <a
                  href="#"
                  data-toggle="modal"
                  :data-target="[
                    '#ellipsis-blue-' + evaluation._id + assessorType + index,
                  ]"
                >
                  <img
                    class="ellipsis-icon"
                    src="@/assets/icons/ellipsis.svg"
                    alt="blue ellipsis icon"
                    height="20px"
                  />
                </a>
                <div
                  class="modal fade"
                  :id="[
                    'ellipsis-blue-' + evaluation._id + assessorType + index,
                  ]"
                  tabindex="-1"
                  role="dialog"
                  aria-labelledby="ellipsis"
                  aria-hidden="true"
                >
                  <div class="modal-dialog" role="document">
                    <div class="modal-content edit-modal">
                      <h2 class="h2 h2--modal text-center mb-0" id="ellipsis">
                        {{ 'What Would You Like To Do?' | translate }}
                      </h2>
                      <div class="modal-body my-0">
                        <button
                          class="btn btn-full btn-full--modal"
                          data-dismiss="modal"
                          data-toggle="modal"
                          :data-target="[
                            '#areYouSure-' +
                              evaluation._id +
                              assessorType +
                              index +
                              'h',
                          ]"
                        >
                          {{ 'Delete' | translate }}
                        </button>
                        <div class="row">
                          <div class="col-md-24 modal-bottom pb-0">
                            <button
                              class="btn-cancel-modal"
                              data-dismiss="modal"
                            >
                              {{ 'Cancel' | translate }}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="modal fade"
                  :id="[
                    'areYouSure-' + evaluation._id + assessorType + index + 'h',
                  ]"
                  tabindex="-1"
                  role="dialog"
                  :aria-labelledby="[
                    'areYouSure-' + evaluation._id + assessorType + index + 'h',
                  ]"
                  aria-hidden="true"
                >
                  <div class="modal-dialog" role="document">
                    <div class="modal-content edit-modal">
                      <h2 class="h2 h2--modal text-center mt-3 mt-md-0">
                        {{ 'Are you sure?' | translate }}
                      </h2>
                      <div class="modal-body my-0">
                        <a
                          @click="deleteAssessor(evaluation, assessorType)"
                          href="#"
                          data-dismiss="modal"
                          class="btn btn-primary btn-full mb-2"
                          >{{ 'Yes' | translate }}</a
                        >
                        <a
                          href="#"
                          data-dismiss="modal"
                          class="btn btn-secondary btn-full btn-full--modal"
                          >{{ 'No' | translate }}</a
                        >
                        <div class="row mt-2">
                          <div class="col-md-24 modal-bottom pb-0">
                            <a
                              href="#"
                              class="btn-cancel-modal"
                              data-dismiss="modal"
                              >{{ 'Cancel' | translate }}</a
                            >
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- end modal -->
              </div>
              <div class="offset-1 col-md-23 col-22 py-0">
                <hr class="hr--bottom m-0 w-100" />
              </div>
            </template>
          </div>
          <div class="row evaluations-row mx-0 py-2">
            <div class="col-md-4 col-22 offset-1 pb-0 pb-md-2">
              <router-link
                :to="
                  '/users/' +
                    userId +
                    '/evaluations/' +
                    evaluation._id +
                    '/order/'
                "
                class="btn btn-primary--thin btn-primary--thin--md-sm btn-full--sm"
                >{{ 'Edit Assessor Order' | translate }}</router-link
              >
            </div>
            <div class="col-md-18 col-22 text-right offset-1 pt-2 pt-md-2">
              <router-link
                :to="
                  '/users/' +
                    userId +
                    '/evaluations/' +
                    evaluation._id +
                    '/groups'
                "
                class="btn btn-sm btn-full--sm mb-2 mb-md-0 ml-0 ml-md-2"
                >{{ 'Add Group' | translate }}</router-link
              >
              <router-link
                v-if="user.role !== ROLES.data_entry"
                :to="'/reports'"
                class="btn btn-sm btn-full--sm ml-0 ml-md-2"
                >{{ 'Reports' | translate }}</router-link
              >
              <button
                v-else-if="
                  user.role === ROLES.data_entry && !$store.state.offline
                "
                data-toggle="modal"
                @click="requestReport(evaluation.name)"
                :data-target="['#requestSent-' + user.id]"
                class="btn btn-sm btn-full--sm ml-0 ml-md-2"
              >
                {{ 'Request Report' | translate }}
                <span v-show="requestReportLoader" class="loader ml-1"></span>
              </button>
            </div>
          </div>
          <div v-show="successMessage || errorMessage" class="offset-1 col-23">
            <div
              v-show="successMessage"
              class="alert alert-primary mt-0 mb-3"
              role="alert"
            >
              {{ 'Your request has been sent!' | translate }}
            </div>

            <div
              v-show="errorMessage"
              class="alert alert-danger mt-0 mb-3"
              role="alert"
            >
              {{ 'An error has occurred during your request!' | translate }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
// @nice links to the Reports page, and opens up the accordion of the clicked evaluation
import axios from 'axios';
import _ from 'lodash';
import Status from '@/components/evaluations/status.vue';
import GroupCount from '@/components/evaluations/groupCount.vue';
import ModalExec from '@/components/misc/modal-exec.vue';
import ModalEllipsis from '@/components/misc/modal-ellipsis.vue';
import Modal from '@/components/misc/modal';
import { mapState, mapActions, mapGetters } from 'vuex';
import requestReportMixin from '@/mixins/requestReport.js';
import {
  GET_CUSTOMER_EVALUATIONS,
  GET_DELETE_ASSESSOR,
  GET_EVALUATION_BY_USER_ID_GETTER,
  GET_EVALUATION_DELETE,
  SET_EVALUATIONS,
  GET_USER_EVALUATIONS_GETTER,
} from '../../store/types';
import { ROLES } from '../../constants';
export default {
  components: {
    Status,
    GroupCount,
    ModalExec,
    ModalEllipsis,
    Modal,
  },
  mixins: [requestReportMixin],
  props: {
    customerId: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      currentSort: 'name',
      currentSortDir: 'asc',
      ellipsisModal: false,
      errorMsg: null,
      isOpen: false,
      loading: true,
      sorted: 'name',
      successMessage: null,
      userType: null,
      ROLES,
      // customerEvaluation: null,
      evaluations: [],
    };
  },
  computed: {
    ...mapState({
      allEvaluations: state => state.evaluations.evaluations,
      user: state => state.users.user,
    }),
    ...mapGetters('evaluations', [
      GET_EVALUATION_BY_USER_ID_GETTER,
      GET_USER_EVALUATIONS_GETTER,
    ]),
    userId() {
      return this.customerId || this.user._id;
    },

  },
  methods: {
    ...mapActions('evaluations', [
      GET_EVALUATION_DELETE,
      GET_CUSTOMER_EVALUATIONS,
    ]),
    ...mapActions('assessors', [GET_DELETE_ASSESSOR]),

    checkIsAssessorsExist(evaluation) {
      return evaluation.groups && Object.keys(evaluation.groups).length > 0;
    },

    // Fetch the evaluation base on the if customer exist.
    async getEvaluation() {
      try {
        this.loading = true;
        this.errorMsg = null;
        this.errorCode = null;

        if (this.customerId) {
          this.userType = 'customer';
          this.evaluations = await this[GET_CUSTOMER_EVALUATIONS](
            this.customerId
          );
        } else {
          // this.evaluations = this[GET_USER_EVALUATIONS_GETTER];
          this.evaluations = this.allEvaluations;
          this.userType = 'user';
        }
        this.loading = false;
        this.$forceUpdate()
      } catch (error) {
        console.error('Error: ', error);
      }
    },

    async sortedEvaluations() {
      this.evaluations = this.evaluations.sort((a, b) => {
        let caseA;
        let caseB;
        switch (this.currentSort) {
          case 'name':
            caseA = a.name ? a.name.toLowerCase() : '';
            caseB = b.name ? b.name.toLowerCase() : '';
            break;
          case 'total_group_count':
            caseA = a.groups ? Object.values(a.groups).length : 0;
            caseB = b.groups ? Object.values(b.groups).length : 0;
            break;
          case 'date':
            caseA = new Date(new Date(a.date_created).getTime() * 1000);
            caseB = new Date(new Date(b.date_created).getTime() * 1000);
            break;
          case 'dairy':
            caseA = this.getDairyLocationName(a);
            caseB = this.getDairyLocationName(b);
            break;
          case 'company':
            caseA = this.getClientName(a);
            caseB = this.getClientName(b);
            break;
          case 'status':
            caseA = this.$store.getters.getEvaluationStatus(a);
            caseB = this.$store.getters.getEvaluationStatus(b);
            break;
          default:
            console.log('Default Called');
        }
        let modifier = 1;
        if (this.currentSortDir === 'desc') modifier = -1;
        if (caseA < caseB) return -1 * modifier;
        if (caseA > caseB) return 1 * modifier;
        return 0;
      });
      // this.$store.commit(`evaluations/${SET_EVALUATIONS}`, evaluation);
    },

    sort(s) {
      //if s == current sort, reverse
      if (s === this.currentSort) {
        this.currentSortDir = this.currentSortDir === 'asc' ? 'desc' : 'asc';
      }
      this.currentSort = s;
    },

    getEvaluationOrder(evaluation) {
      if (evaluation.order && evaluation.order.length > 0) {
        return evaluation.order;
      } else {
        if (!evaluation.groups) {
          return null;
        }

        let assessorsInEvaluations = [];

        if (Array.isArray(evaluation.groups)) {
          assessorsInEvaluations = evaluation.groups.map(
            group => group.assessor_list
          );
        } else {
          for (let groupKey in evaluation.groups) {
            assessorsInEvaluations.push(
              evaluation.groups[groupKey].assessor_list
            );
          }
        }

        assessorsInEvaluations = _.flatMap(assessorsInEvaluations);

        return _.uniqBy(assessorsInEvaluations, assessor => assessor);
      }
    },

    displayError(errorCode, message = '') {
      console.log('errorCode', errorCode);
      // if (errorCode === 'request-report') {
      //   this.errorMsg = `${message} something went wrong`;
      // } else
      if (errorCode === 'evaluation/not-found') {
        this.errorMsg = 'Sorry! We can not find that evaluation at this time.';
      }
      // else {
      //   this.errorMsg = 'Sorry! Something went wrong.';
      // }
    },

    getFirstGroupByAssessorType(groups, assessorTypeId) {
      for (let groupId in groups) {
        if (groups[groupId].assessor_list) {
          const assessor = groups[groupId].assessor_list.find(assessorType => {
            return assessorType === assessorTypeId;
          });
          if (assessor) {
            return groups[groupId] || {};
          }
        }
      }
      return {};
    },

    getAssessorTypeNameById(assessorTypeId) {
      const assessorTypeInfo = this.$store.state.assessorTypes.find(
        assessorType => {
          return assessorType.id == assessorTypeId;
        }
      );

      return assessorTypeInfo ? assessorTypeInfo.name : assessorTypeInfo;
    },

    getFirstAssessorByType(evaluation, assessorType) {
      if (typeof evaluation.groups == 'undefined') return;

      return evaluation.groups[0].assessors.find(assessor => {
        return assessor.type == assessorType;
      });
    },

    getDairyLocationName(evaluation) {
      // Return empty string if dairyLocation is undefined
      const path = `clientData.dairy_locations.${evaluation.dairyLocationId}.name`;
      return _.get(evaluation, path, '');
    },

    getClientName(evaluation) {
      return _.get(evaluation, 'clientData.name', '');
    },

    getAssessorById(assessorId) {
      return this.evaluation.assessors.find(assessor => {
        return assessor.id === assessorId;
      });
    },

    getReportName(id) {
      if (id == null) return;
      return 'Company Name';
    },

    async deleteEvaluation(evaluationId) {
      this[GET_EVALUATION_DELETE](evaluationId);
      this.evaluations = this.allEvaluations
      this.sortedEvaluations();
    },
    async deleteAssessor(evaluation, assessorType) {
      const groupId = this.getFirstGroupByAssessorType(
        evaluation.groups,
        assessorType
      ).id;
      const evaluationId = evaluation._id;
      this[GET_DELETE_ASSESSOR]({
        evaluationId,
        groupId,
        assessorId: assessorType,
      })
    },
  },
  mounted() {
    setTimeout(() => {
      this.getEvaluation();
    }, 1500);
  },
  watch: {
    customerId(newCustomerId) {
      this.getEvaluation(newCustomerId);
    },
    user(user) {
      this.getEvaluation();
    },
  },
};
</script>
