const PieChart = require('../../Misc/PieChart');
const Table = require('../../Misc/Table');

export const group = (app, report, evaluation, scoringType) => {

    let assessorType = "3";
    let groups = [];

    const selectedGroups = evaluation.getGroups().filter( (item) => {
        item = item.group.id;
        return evaluation.getReport().groups.includes(item);
    });

    for ( let i = 0; i < selectedGroups.length; i++ ) {
        let group = selectedGroups[i];
        let locomotion = group.getAssessors().find(assessor => {
            return assessor.getType() === assessorType;
        });

        if ( locomotion !== undefined ) {
            if ( locomotion.getScoringSystem() === scoringType ) {
                groups.push(group);
            }
        }
    }


    // if there are is less than 2 groups with locomotions, do not add this slide
    if ( groups.length < 2 ) return false;

    let slide = report.pptx.addSlide('Slide master');

    slide.addText(
        app.$t('Locomotion Assessor'), report.styleOptions.slideHeader
    );

    slide.addText(
        app.$t('Group Comparison'), {
            x: 0.5,
            y: 2.38,
            ...report.styleOptions.subheaderBlue
        }
    );

    let obj = [
        {
            text: app.$t('Locomotion Score'),
            options: report.styleOptions.tableHeader
        },
        {
            text: app.$t('1 (Target %)'),
            options: report.styleOptions.tableHeader
        },
        {
            text: app.$t('2 (Target %)'),
            options: report.styleOptions.tableHeader
        },
        {
            text: app.$t('3 (Target %)'),
            options: report.styleOptions.tableHeader
        }
    ];

    if ( scoringType === 1 ) {
        obj.push({
            text: app.$t('4 (Target %)'),
            options: report.styleOptions.tableHeader
        });
        obj.push({
            text: app.$t('5 (Target %)'),
            options: report.styleOptions.tableHeader
        });
    } else {
        obj.push({
            text: app.$t('4 (Target %)'),
            options: report.styleOptions.tableHeader
        });
    }

    obj = obj.concat([
        {
            text: app.$t('Total Scored (Target)'),
            options: report.styleOptions.tableHeader
        },
        {
            text: app.$t('Average Daily Milk Loss (') + app.$t(app.$getEquivalentUnit(evaluation.getMeasurements(), 'kg')) + app.$t('/cow/day)*'),
            options: report.styleOptions.tableHeader
        },
        {
            text: app.$t('Lost Revenue (') + evaluation.getCurrency() + app.$t('/group/yr)*'),
            options: report.styleOptions.tableHeader
        }
    ]);

    let obj2 = [];

    let totalArr = []
    let score1Arr = []
    let score2Arr = []
    let score3Arr = []
    let score4Arr = []
    let score45Arr = []
    let score5Arr = []

    // for weighted average
    let scoreOneTotal = 0
    let scoreTwoTotal = 0
    let scoreThreeTotal = 0
    let scoreFourTotal = 0
    let scoreFiveTotal = 0
    let scoreFourAndFiveTotal = 0
    let totalOfAllScore = 0

    let target1 = []
    let target2 = []
    let target3 = []
    let target4 = []
    let target45 = []
    let target5 = []


    var rectangleHeight = 3.85;
    var pieHeight = 3.95;

    var milkLoss = [];
    var lostRev = [];

    var locomotionAssessor
    let rowCount = 0;

    for ( let j = 0; j < groups.length; j++ ) {

        locomotionAssessor = groups[j].getAssessorByType("3");

        if (locomotionAssessor.getTotalScore() === 0) continue;
        
        // // collecting data for weigted average
        scoreOneTotal += locomotionAssessor.getScoreValue('locomotion_score_1')
        scoreTwoTotal += locomotionAssessor.getScoreValue('locomotion_score_2')
        scoreThreeTotal += locomotionAssessor.getScoreValue('locomotion_score_3')
        if(scoringType === 1){
            scoreFourTotal += locomotionAssessor.getScoreValue('locomotion_score_4')
            scoreFiveTotal += locomotionAssessor.getScoreValue('locomotion_score_5')
        }else {
            scoreFourAndFiveTotal += locomotionAssessor.getScoreValue('locomotion_score_45')
        }
        // end for collecteding weighted average
        
        totalArr.push(locomotionAssessor.getTotalScore());

        score1Arr.push(locomotionAssessor.getPercentageOfTotalScore(1, true))
        target1.push(locomotionAssessor.getTarget(1))
        score2Arr.push(locomotionAssessor.getPercentageOfTotalScore(2, true))
        target2.push(locomotionAssessor.getTarget(2))
        score3Arr.push(locomotionAssessor.getPercentageOfTotalScore(3, true))
        target3.push(locomotionAssessor.getTarget(3))

        if ( scoringType === 1 ) {
            score4Arr.push(locomotionAssessor.getPercentageOfTotalScore(4, true));
            target4.push(locomotionAssessor.getTarget(4))
            score5Arr.push(locomotionAssessor.getPercentageOfTotalScore(5, true));
            target5.push(locomotionAssessor.getTarget(5))
        } else {
            score45Arr.push(locomotionAssessor.getPercentageOfTotalScore(45, true));
            target45.push(locomotionAssessor.getTarget(45))
        }

        if ( locomotionAssessor.getEconomics() === 1 ) {
            milkLoss.push(locomotionAssessor.getAverageDailyMilkLoss(true));
            lostRev.push(locomotionAssessor.getLostRevenueYearly(true));
        }

        obj2[rowCount] = [
            {
                text: groups[j].getName() + ' (' + locomotionAssessor.getTotalScore() + app.$t(' cows)'),
                options: {
                    color: '000000',
                    fill: 'ffffff',
                    bold: false,
                    align: 'left'
                }
            },
            {
                text: locomotionAssessor.getPercentageOfTotalScore(1) + app.$t('% (') + locomotionAssessor.getTarget(1) + app.$t('%)'),
                options: {
                    color: '000000',
                    fill: 'ffffff',
                    bold: false
                }
            },
            {
                text: locomotionAssessor.getPercentageOfTotalScore(2) + app.$t('% (') + locomotionAssessor.getTarget(2) + app.$t('%)'),
                options: {
                    color: '000000',
                    fill: 'ffffff',
                    bold: false
                }
            },
            {
                text: locomotionAssessor.getPercentageOfTotalScore(3) + app.$t('% (') + locomotionAssessor.getTarget(3) + app.$t('%)'),
                options: {
                    color: '000000',
                    fill: 'ffffff',
                    bold: false
                }
            }
        ];

        if ( scoringType === 1 ) {
            obj2[rowCount].push({
                text: locomotionAssessor.getPercentageOfTotalScore(4) + app.$t('% (') + locomotionAssessor.getTarget(4) + app.$t('%)'),
                options: {
                    color: '000000',
                    fill: 'ffffff',
                    bold: false
                }
            });
            obj2[rowCount].push({
                text: locomotionAssessor.getPercentageOfTotalScore(5) + app.$t('% (') + locomotionAssessor.getTarget(5) + app.$t('%)'),
                options: {
                    color: '000000',
                    fill: 'ffffff',
                    bold: false
                }
            });
        } else {
            obj2[rowCount].push({
                text: locomotionAssessor.getPercentageOfTotalScore(45) + app.$t('% (') + locomotionAssessor.getTarget(45) + app.$t('%)'),
                options: {
                    color: '000000',
                    fill: 'ffffff',
                    bold: false
                }
            });
        }

        obj2[rowCount] = obj2[rowCount].concat([
            {
                text: locomotionAssessor.getTotalScore(),
                options: {
                    color: '000000',
                    fill: 'ffffff',
                    bold: false
                }
            },
            {
                text: locomotionAssessor.getEconomics() === 1 ? locomotionAssessor.getAverageDailyMilkLoss() : '',
                options: {
                    color: '000000',
                    fill: 'ffffff',
                    bold: false
                }
            },
            {
                text: locomotionAssessor.getEconomics() === 1 ? locomotionAssessor.getLostRevenueYearly() : '',
                options: {
                    color: '000000',
                    fill: 'ffffff',
                    bold: false
                }
            }
        ]);

        rectangleHeight += 0.25;
        pieHeight += 0.25;

        if (locomotionAssessor.getPresentationNotes() !== null) {
            rowCount++;

            obj2[rowCount] = [
                {
                    text: app.$t('Comments'),
                    options: {
                        color: '000000',
                        fill: 'efefef',
                        align: 'left'
                    }
                },
                {
                    text: locomotionAssessor.getPresentationNotes(),
                    options: {
                        color: '000000',
                        fill: 'efefef',
                        bold: false,
                        align: 'l',
                        colspan: (scoringType === 1) ? 9 : 8
                    }
                }
            ];
            rectangleHeight += 0.4;
            pieHeight += 0.4;
        }

        rowCount++;
    };

    let scoreTotal1 = score1Arr.reduce((a,b) => a + b, 0);
    let scoreTotal2 = score2Arr.reduce((a,b) => a + b, 0);
    let scoreTotal3 = score3Arr.reduce((a,b) => a + b, 0);
    let scoreTotal4;
    let scoreTotal5;
    let scoreTotal45;

    let score1Avg = scoreTotal1 / score1Arr.length;
    let score2Avg = scoreTotal2 / score2Arr.length;
    let score3Avg = scoreTotal3 / score3Arr.length;
    let score4Avg;
    let score5Avg;
    let score45Avg;

    let totalAvg = score1Avg + score2Avg + score3Avg;
    let total = scoreTotal1 + scoreTotal2 + scoreTotal3
    totalOfAllScore = scoreOneTotal + scoreTwoTotal + scoreThreeTotal
    // let total = 25
    if ( scoringType === 1 ) {
        scoreTotal4 = score4Arr.reduce((a,b) => a + b, 0);
        score4Avg = scoreTotal4 / score4Arr.length;
        totalAvg += score4Avg;
        scoreTotal5 = score5Arr.reduce((a,b) => a + b, 0);
        score5Avg = scoreTotal5 / score5Arr.length;
        totalAvg += score5Avg;
        totalOfAllScore += (scoreFourTotal + scoreFiveTotal)

    } else {
        scoreTotal45 = score45Arr.reduce((a,b) => a + b, 0);
        score45Avg = scoreTotal45 / score45Arr.length;
        totalAvg += score45Avg;
        totalOfAllScore += scoreTotal45

    }
  
       

    let averageScores = [
        {
            text: app.$t('Average Score *'),
            options: {
                color: '000000',
                bold: true,
                fill: 'ffffff',
                align: 'left'
            }
        },
        {
            // text: (score1Avg / totalAvg * 100).toFixed(2) + app.$t('% (') + locomotionAssessor.getPercentageOfTargetScore(1) + app.$t('%)'),
            // text: (score1Avg / totalAvg * 100).toFixed(2) + app.$t('% (') + Math.round(target1.reduce((a,b) => a + b, 0) / target1.length) + app.$t('%)'),
            text: (scoreOneTotal / totalOfAllScore * 100).toFixed(1) + app.$t('% (') + Math.round(target1.reduce((a,b) => a + b, 0) / target1.length) + app.$t('%)'),
          
            options: {
                color: '000000',
                fill: 'ffffff',
                bold: false
            }
        },
        {
            // text: (score2Avg / totalAvg * 100).toFixed(2) + app.$t('% (') + locomotionAssessor.getPercentageOfTargetScore(2) + app.$t('%)'),
            text: (scoreTwoTotal / totalOfAllScore * 100).toFixed(1) + app.$t('% (') + Math.round(target2.reduce((a,b) => a + b, 0) / target2.length) + app.$t('%)'),
            options: {
                color: '000000',
                fill: 'ffffff',
                bold: false
            }
        },
        {
            // text: (score3Avg / totalAvg * 100).toFixed(2) + app.$t('% (') + locomotionAssessor.getPercentageOfTargetScore(3) + app.$t('%)'),
            text: (scoreThreeTotal/ totalOfAllScore * 100).toFixed(1) + app.$t('% (') + Math.round(target3.reduce((a,b) => a + b, 0) / target3.length) + app.$t('%)'),
            options: {
                color: '000000',
                fill: 'ffffff',
                bold: false
            }
        }
    ];

    if ( scoringType === 1 ) {
        averageScores.push({
            // text: (score4Avg / totalAvg * 100).toFixed(2) + app.$t('% (') + locomotionAssessor.getPercentageOfTargetScore(4) + app.$t('%)'),
            text: (scoreFourTotal / totalOfAllScore * 100).toFixed(1) + app.$t('% (') + Math.round(target4.reduce((a,b) => a + b, 0) / target4.length) + app.$t('%)'),
            options: {
                color: '000000',
                fill: 'ffffff',
                bold: false
            }
        });
        averageScores.push({
            // text: (score5Avg / totalAvg * 100).toFixed(2) + app.$t('% (') + locomotionAssessor.getPercentageOfTargetScore(5) + app.$t('%)'),
            text: (scoreFiveTotal / totalOfAllScore * 100).toFixed(1) + app.$t('% (') + Math.round(target5.reduce((a,b) => a + b, 0) / target5.length) + app.$t('%)'),
            options: {
                color: '000000',
                fill: 'ffffff',
                bold: false
            }
        });
    } else {
        averageScores.push({
            // text: (score45Avg / totalAvg * 100).toFixed(2) + app.$t('% (') + locomotionAssessor.getPercentageOfTargetScore(45) + app.$t('%)'),
            text: (scoreFourAndFiveTotal / totalOfAllScore * 100).toFixed(1) + app.$t('% (') + Math.round(target45.reduce((a,b) => a + b, 0) / target45.length) + app.$t('%)'),
            options: {
                color: '000000',
                fill: 'ffffff',
                bold: false
            }
        });
    }

    obj2[rowCount] = averageScores;

    obj2[rowCount] = obj2[rowCount].concat([
        {
            text: Math.round(totalArr.reduce((a,b) => a + b, 0),0),
            options: {
                color: '000000',
                fill: 'ffffff',
                bold: false
            }
        },
        {
            text: ( _.sum(milkLoss) / milkLoss.length ).toFixed(2),
            options: {
                color: '000000',
                fill: 'ffffff',
                bold: false
            }
        },
        {
            text: ( _.sum(lostRev) / lostRev.length ).toFixed(2),
            options: {
                color: '000000',
                fill: 'ffffff',
                bold: false
            }
        }
    ]);

    rowCount++;
    obj2[rowCount] = [
        {
            text: app.$t('Herd Size and Economic Loss') + ': ',
            options: {
                color: '000000',
                fill: 'efefef',
                align: 'r',
                colspan: 6
            }
        },
        {
            text: evaluation.getHerdSize(),
            options: {
                color: '000000',
                bold: false,
                fill: 'efefef'
            }
        },
        {
            text: '',
            options: {
                color: '000000',
                fill: 'efefef',
                bold: false
            }
        },
        {
            text: '',
            options: {
                color: '000000',
                fill: 'efefef',
                bold: false
            }
        }
    ];

    obj2[rowCount] = obj2[rowCount].concat([
        {
            text: app.$t('*Locomotion score % is weighted based on the number of animals in each group. '),
            options: {
                color: 'ffffff',
                fill: '0000FF',
                align: 'c',
                colspan: (scoringType === 1) ? 9 : 8
            }
        }
    ]);

    rectangleHeight += 1;
    pieHeight += 1;

    let colW = [1.5 ];
    if ( scoringType === 1 ) {
        colW = colW.concat([ 0.7, 0.7, 0.7, 0.7, 0.7, .83, .83, .83]);
    } else {
        colW = colW.concat([ 0.75, 0.75, 0.75, 0.75, 1, 1, 1]);
    }

    Table.create(slide, obj, obj2, report, { app }, 'Locomotion Assessor', {
        ...report.styleOptions.tableOptions,
        autoPageLineWeight: .25,
        y: 2.7,
        fontSize: 9.5,
        border: {
            pt: '1',
            color: '9a9a9a'
        },
        margin: 3.5,
        colW: colW
    });

    let pieChartSlide = slide
    if (groups.length > 3) {
        pieChartSlide = report.pptx.addSlide('Slide master');
        pieChartSlide.addText(
          app.$t('Locomotion Assessor'), report.styleOptions.slideHeader
        );
        pieChartSlide.addText(
          app.$t('Group Comparison'), {
              x: 0.5,
              y: 2.38,
              ...report.styleOptions.subheaderBlue
          }
        );
        rectangleHeight = 2.7
        pieHeight = 2.7
    }

    pieChartSlide.addShape(report.pptx.shapes.RECTANGLE, {
        x: 0.5,
        y: rectangleHeight,
        w: 7.5,
        h: 3,
        line: '000000',
        line_size: 1
    });


    let pieObj = [{
        name: app.$t('Locomotion Scoring'),
        labels: [
            app.$t('Score 1') + ' (' + (scoreOneTotal / totalOfAllScore * 100).toFixed(1) + app.$t('%)'),
            app.$t('Score 2') + ' (' + (scoreTwoTotal / totalOfAllScore * 100).toFixed(1) + app.$t('%)'),
            app.$t('Score 3') + ' (' + (scoreThreeTotal / totalOfAllScore * 100).toFixed(1) + app.$t('%)'),
        ],
        values: [
            (scoreOneTotal / totalOfAllScore * 100),
            (scoreTwoTotal / totalOfAllScore * 100),
            (scoreThreeTotal / totalOfAllScore * 100)
        ]
    }];

    if ( scoringType === 1 ) {
        pieObj[0].labels.push(app.$t('Score 4') + ' (' + (scoreFourTotal / totalOfAllScore * 100).toFixed(1) + app.$t('%)'));
        pieObj[0].values.push((scoreFourTotal /  totalOfAllScore  * 100));
        pieObj[0].labels.push(app.$t('Score 5') + ' (' + (scoreFiveTotal / totalOfAllScore * 100).toFixed(1) + app.$t('%)'));
        pieObj[0].values.push((scoreFiveTotal / totalOfAllScore * 100));
    } else {
        pieObj[0].labels.push(app.$t('Score 4') + ' (' + (scoreFiveTotal / totalOfAllScore * 100).toFixed(1) + app.$t('%)'));
        pieObj[0].values.push((scoreFourAndFiveTotal / totalOfAllScore) * 100);
    }

    PieChart.create(report,  pieChartSlide, pieObj, {
        x: 0.75,
        y: pieHeight,
        w: 7,
        h: 2.75,
    });

    return true;
}
